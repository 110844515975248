/**
 * @file Handles request polling
 * */
import { fetchJson } from '@@/surface/api_fetch'
import type { FetchOptions } from '@padlet/fetch'
import { HTTPMethod } from '@padlet/fetch'

interface PollingOptions {
  intervalSecs: number
  maxAttempts: number
  method?: HTTPMethod
  fetchOptions?: FetchOptions
}

interface PollingArguments {
  pollingUrl: string
  validationCallback?: (response) => boolean
  options: PollingOptions
}

const defaultValidationCallback = (response): boolean => response.done

const poll = async ({ pollingUrl, validationCallback, options }: PollingArguments): Promise<any> => {
  let attempts = 0
  const executePoll = async (resolve, reject): Promise<void> => {
    const fetchOptions = options.fetchOptions ?? {}
    const response = await fetchJson(pollingUrl, {
      method: options.method ?? HTTPMethod.post,
      ...fetchOptions,
    })
    attempts++
    try {
      if (validationCallback != null ? validationCallback(response) : defaultValidationCallback(response)) {
        return resolve(response)
      } else if (attempts >= options.maxAttempts) {
        return reject()
      } else {
        setTimeout(executePoll, options.intervalSecs * 1000, resolve, reject)
      }
    } catch (validationError) {
      return reject(validationError)
    }
  }
  return await new Promise(executePoll)
}

export { poll }
